import React from "react"
import { graphql } from "gatsby"

import BlogTemplate from "../templates/blogTemplate"
import "./press.css"

const Press = ({ data }) => <BlogTemplate data={data}></BlogTemplate>

export default Press

export const pageQuery = graphql`{
  file(relativePath: {eq: "Ngan_Mythren_Promo_art.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 1400, height: 600, quality: 100, layout: FIXED)
    }
  }
  markdownRemark(fileAbsolutePath: {regex: "/press.md/"}) {
    html
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      name
      id
      slug
      title
      featuredImage {
        childImageSharp {
          gatsbyImageData(width: 1400, height: 600, quality: 100, layout: FIXED)
        }
      }
    }
  }
}
`
